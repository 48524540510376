import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls';
import { TableMode } from '../../models/Common';
import { useRoutingRuleContext } from '../../RoutingRuleContext';
import RoutingRulesActionCard from './RoutingRulesActionCard';
import RoutingRulesImportActionCard from './RoutingRulesImportActionCard';
import RoutingRulesTable from './RoutingRulesTable';

type Props = {
    cardProps?: any;
}

const RoutingRulesCard = (props: Props) => {
    const routingRulesContext = useRoutingRuleContext()
    const routingRuleTableMode = (routingRulesContext.importedRuleSet?.decisionRules && routingRulesContext.importedRuleSet.decisionRules.length > 0)
        ? TableMode.Import
        : TableMode.Normal

    return (
        <Card {...props.cardProps}>
            <CardHeader
                className='border-0'
                title="Routing Rules"
                {...props.cardProps}
            >
                <CardHeaderToolbar {...props.cardProps}>
                    <button
                        type="button"
                        className="border-0 bg-transparent"
                        onClick={routingRulesContext.uiEvents.showTestCasePage}>
                        <u>Show Test Cases</u>
                    </button>
                </CardHeaderToolbar>

            </CardHeader>
            <CardBody {...props.cardProps}>
                <div className='mb-6'>
                    {routingRuleTableMode === TableMode.Normal && <RoutingRulesActionCard />}
                    {routingRuleTableMode === TableMode.Import && <RoutingRulesImportActionCard />}
                </div>
                <RoutingRulesTable tableMode={routingRuleTableMode} />
            </CardBody>
        </Card>
    );
}

export default RoutingRulesCard;