import { useDispatch } from "react-redux";
import * as actions from '../../redux/RoutingRulesActions'

type Props = {
}

const TestCasesImportActionCard = (props: Props) => {
    const dispatch = useDispatch();

    const handleCancelImportClick = () => {
        dispatch(actions.cancelTestCasesImport())
    }

    const handleAcceptImportClick = () => {
        dispatch(actions.acceptTestCasesImport())
    }

    return (
        <div className="d-flex bg-secondary rounded p-3 row">
            <div className="col" />
            <button
                className="border-0 bg-white rounded mx-2 py-5 col-2"
                onClick={handleCancelImportClick}
                onMouseDown={e => e.preventDefault()}
            >
                Cancel Import
            </button>
            <button
                className="border-0 bg-white rounded mx-2 py-5 col-2"
                onClick={handleAcceptImportClick}
                onMouseDown={e => e.preventDefault()}
            >
                Accept Import
            </button>
            <div className="col" />
        </div>
    )
}

export default TestCasesImportActionCard