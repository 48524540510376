
interface ExportJSONProps {
    data: Object
    fileName: string
    className?: string
    children?: any
}

const ExportJSON: React.FC<ExportJSONProps> = ({ data, fileName, className, children }) => {
    const downloadJSON = () => {
        // Convert the data array into a JSON string
        const jsonString = JSON.stringify(data)

        // Create a Blob from the JSON string
        const blob = new Blob([jsonString], { type: 'text/json' });

        // Generate a download link and initiate the download
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName || `download.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    return <button
        className={className ?? ""}
        onClick={downloadJSON}
        onMouseDown={e => e.preventDefault()}
    >
        {children ?? "Export JSON"}
    </button>;
};

export default ExportJSON;