import moment from 'moment';
import ExportJSON from '../../../components/ExportJSON';
import { activityRuleTypes, cleanRoutingRuleSetForPersistence, conditionTypes, logicalOperators } from '../../models/RoutingRuleSetModel';
import { SelectionReason, useRoutingRuleContext } from '../../RoutingRuleContext';
import * as actions from '../../redux/RoutingRulesActions'
import { useDispatch } from 'react-redux';
import { getEnvironment } from '../../../utils/GetEnvironment';

type Props = {
    filterProps?: any;
}

const RoutingRulesActionCard = (props: Props) => {
    const dispatch = useDispatch();
    const routingRulesContext = useRoutingRuleContext();
    const changesPending = routingRulesContext.changedRoutingRules.length > 0

    const handleAddRuleClick = () => {
        const routingRule = {
            id: crypto.randomUUID(),
            name: "",
            sequenceNumber: -1, //Will be updated to be last in the list
            activityRules: [{
                activityRuleType: activityRuleTypes.unconditionalActivationRule,
                active: true
            }],
            conditions: {
                conditionType: conditionTypes.conditionGroup,
                logicalOperator: logicalOperators.and,
                conditions: []
            },
            decision: [{
                weight: 100,
                productionSite: ""
            }]
        }
        routingRulesContext.setSelectedRoutingRule({ routingRule: routingRule, selectionReason: SelectionReason.New })
        routingRulesContext.uiEvents.showRuleEditModal()
    }

    const handleRoutingRulesImport = async (event: any) => {
        dispatch(actions.importRoutingRules(event.target.files[0]))
    }

    return (
        <div className="d-flex justify-content-between bg-secondary rounded p-3 row">
            <div className="d-flex col">
                {/* <input className="width-set-50 border-0 rounded pl-3" type="text" placeholder="SOON!!! filter on factory, condition variable" />
                <div className="ml-3 mt-3"><u>Advanced</u></div> */}
            </div>
            <ExportJSON
                data={cleanRoutingRuleSetForPersistence(routingRulesContext.routingRuleSet)}
                fileName={`routingRules-${getEnvironment()}-${moment().format('YYYY-MM-DD_HH-mm-ss')}-do-not-edit.json`}
                className="border-0 bg-white rounded mx-2 col-2"
            >
                Export Routing Rules
            </ExportJSON>
            <button
                className="border-0 bg-white rounded mx-2 col-2"
                disabled={changesPending}
                onMouseDown={e => e.preventDefault()}
            >
                <label className="px-3 pt-5 pb-3">
                    <input
                        type='file'
                        accept='.json'
                        onChange={handleRoutingRulesImport}
                        hidden
                    />
                    Import Routing Rules
                </label>
            </button>
            <div className='col-2' />
            <button
                className="border-0 bg-white rounded mx-2 col-2"
                onClick={handleAddRuleClick}
                onMouseDown={e => e.preventDefault()}
            >
                <div className="d-flex justify-content-center align-content-center">
                    <div className="h1 text-primary mt-2">+</div>
                    <div className="ml-3 mt-4">Add new rule</div>
                </div>
            </button>
        </div>
    )
}

export default RoutingRulesActionCard