import { createSlice } from '@reduxjs/toolkit';
import { deepCloneRoutingRule, IRoutingRule, IRoutingRuleSet, RoutingRuleChange } from '../models/RoutingRuleSetModel';
import { RoutingRulesAlert } from '../models/RoutingRulesAlert';
import { TestCase, TestResult, TestCaseResult, TestCaseChange, cloneTestCase, isEqualTestCase } from '../models/TestSuiteModel';
import { ArticleType, Brand, Channel } from '../models/RoutingRuleDropdownData';
import { ChangeType } from '../models/Common';
import { findNextMatch, getRoutingRuleHash, Match } from '../utils/ImportHelpers';

export interface RoutingRulesData {
    routingRuleSet: IRoutingRuleSet,
    importedRuleSet: IRoutingRuleSet,
    testCases: TestCase[],
    importedTestCases: TestCase[],
    articleTypes: ArticleType[],
    brands: Brand[],
    channels: Channel[],
    nrApiCallsInProgress: number,
    alert: RoutingRulesAlert | null;
};

const initialRoutingRulesState: RoutingRulesData = {
    routingRuleSet: {
        name: "",
        description: "",
        timestamp: "",
        version: 0,
        decisionRules: []
    },
    importedRuleSet: {
        name: "",
        description: "",
        timestamp: "",
        version: 0,
        decisionRules: []
    },
    testCases: [],
    importedTestCases: [],
    articleTypes: [],
    brands: [],
    channels: [],
    nrApiCallsInProgress: 0,
    alert: null
};

export const callTypes = {
    get: 'get',
    post: 'post',
    put: 'put',
    file: 'file'
};

export const routingRulesSlice = createSlice({
    name: 'routingRulesData',
    initialState: initialRoutingRulesState,
    reducers: {
        catchError: (state, action) => {
            state.alert = action.payload.alert;
            if (action.payload.callType === callTypes.get ||
                action.payload.callType === callTypes.post ||
                action.payload.callType === callTypes.put) {
                state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            }
        },
        startCall: (state, action) => {
            state.alert = null;
            if (action.payload.callType === callTypes.get ||
                action.payload.callType === callTypes.post ||
                action.payload.callType === callTypes.put) {
                state.nrApiCallsInProgress = state.nrApiCallsInProgress + 1;
            }
        },
        dismissAlert: (state) => {
            state.alert = null;
        },
        routingRulesFetched: (state, action) => {
            state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            state.alert = null;
            state.routingRuleSet = action.payload;
        },
        testCasesFetched: (state, action) => {
            state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            state.alert = null;
            state.testCases = action.payload;
        },
        testSuiteResultsFetched: (state, action) => {
            state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            state.alert = null;
            const testResults = action.payload as TestResult[]
            state.testCases = state.testCases.map((test) => {
                const result = testResults.find((result) => test.id === result.testCase.id)
                return (result)
                    ? {
                        ...test,
                        testResult: {
                            failureReason: result.failureReason,
                            result: result.result,
                            selectedProductionSite: result.selectedProductionSite,
                            selectedRule: { ...result.selectedRule }
                        } as TestCaseResult
                    }
                    : test
            })
        },
        articleTypesFetched: (state, action) => {
            state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            state.alert = null;
            const articleTypes = action.payload.map((at: any) => {
                return {
                    ...at,
                    articles: at.articles.map((a: string) => a.toLowerCase())
                }
            }) as ArticleType[];
            articleTypes.sort((a: ArticleType, b: ArticleType) => a.name.localeCompare(b.name));
            articleTypes.forEach(at => at.articles.sort())
            state.articleTypes = articleTypes;
        },
        brandsFetched: (state, action) => {
            state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            state.alert = null;
            const brands = [...action.payload] as Brand[];
            brands.sort((a: Brand, b: Brand): number => {
                return a.name.localeCompare(b.name)
            });
            brands.forEach(brand => brand.channels.sort())
            state.brands = brands
        },
        channelsFetched: (state, action) => {
            state.nrApiCallsInProgress = Math.max(state.nrApiCallsInProgress - 1, 0);
            state.alert = null;
            const channels = [...action.payload] as Channel[];
            channels.sort((a: Channel, b: Channel): number => {
                //sort alphabelically on Brand, then channel
                if (a.brand !== b.brand) {
                    return a.brand.localeCompare(b.brand)
                }
                return a.name.localeCompare(b.name)
            });
            state.channels = channels
        },
        routingRulesImported: (state, action) => {
            const incomingRoutingRules = action.payload.decisionRules as IRoutingRule[]
            incomingRoutingRules.sort((a, b) => a.sequenceNumber - b.sequenceNumber)
            const incomingRoutingRuleHashes = incomingRoutingRules.map(r => getRoutingRuleHash(r))
            const currentRoutingRules = state.routingRuleSet.decisionRules
            currentRoutingRules.sort((a, b) => a.sequenceNumber - b.sequenceNumber)
            const currentRoutingRuleHashes = currentRoutingRules.map(r => getRoutingRuleHash(r))
            const importedRoutingRules = [] as IRoutingRule[]

            //create an array ordered by sequenceNumber of imported and existing rules, with all changes marked as either New or Deleted
            let lastMatch = { incomingIndex: -1, currentIndex: -1, foundMatch: false } as Match
            let nextMatch = findNextMatch(lastMatch, incomingRoutingRuleHashes, currentRoutingRuleHashes)
            while (true) {
                if (nextMatch.foundMatch) {
                    //add incoming between matches as new
                    incomingRoutingRules.filter((r, x) => x > lastMatch.incomingIndex && x < nextMatch.incomingIndex)
                        .forEach(r => {
                            const rule = deepCloneRoutingRule(r)
                            rule.routingRuleChange = { changeType: ChangeType.New } as RoutingRuleChange
                            importedRoutingRules.push(rule)
                        })
                    //add current between matches as deleted
                    currentRoutingRules.filter((r, x) => x > lastMatch.currentIndex && x < nextMatch.currentIndex)
                        .forEach(r => {
                            const rule = deepCloneRoutingRule(r)
                            rule.id = "deleted-" + rule.id
                            rule.routingRuleChange = { changeType: ChangeType.Deleted, originalRoutingRule: deepCloneRoutingRule(r) } as RoutingRuleChange
                            importedRoutingRules.push(rule)
                        })
                    //add matched rule as unchanged
                    importedRoutingRules.push(incomingRoutingRules[nextMatch.incomingIndex])
                } else {
                    //final, add remaining incoming as new, add remaining current as deleted
                    incomingRoutingRules.filter((r, x) => x > lastMatch.incomingIndex)
                        .forEach(r => {
                            const rule = deepCloneRoutingRule(r)
                            rule.routingRuleChange = { changeType: ChangeType.New } as RoutingRuleChange
                            importedRoutingRules.push(rule)
                        })
                    currentRoutingRules.filter((r, x) => x > lastMatch.currentIndex)
                        .forEach(r => {
                            const rule = deepCloneRoutingRule(r)
                            rule.id = "deleted-" + rule.id
                            rule.routingRuleChange = { changeType: ChangeType.Deleted, originalRoutingRule: deepCloneRoutingRule(r) } as RoutingRuleChange
                            importedRoutingRules.push(rule)
                        })
                    break;
                }
                lastMatch = nextMatch
                nextMatch = findNextMatch(lastMatch, incomingRoutingRuleHashes, currentRoutingRuleHashes)
            }
            state.importedRuleSet = {
                description: "Imported Routing rules",
                decisionRules: importedRoutingRules
            } as IRoutingRuleSet
        },
        testCasesImported: (state, action) => {
            //Mark all imported testcases with the result of a comparison to the existing testcase
            const importedTestCases = action.payload as TestCase[]
            importedTestCases.forEach((importedTestCase) => {
                const currentTestCase = state.testCases.find(x => x.id === importedTestCase.id)
                const equalsCurrentTestCase = isEqualTestCase(importedTestCase, (currentTestCase ?? undefined))
                //Imported TestCase is New
                if (!currentTestCase) {
                    importedTestCase.testCaseChange = { changeType: ChangeType.New } as TestCaseChange
                }
                //Imported TestCase is Different
                if (currentTestCase && !equalsCurrentTestCase) {
                    importedTestCase.testCaseChange = { changeType: ChangeType.Changed, originalTestCase: currentTestCase } as TestCaseChange
                }
                //Imported TestCase is unchanged or reverts changes - no TestChange to record
            })
            //Create deletion records for testcases that are no longer present
            const deletedTestCases = state.testCases.filter((currentTestCase) => !importedTestCases.find(x => x.id === currentTestCase.id))
            deletedTestCases.forEach((deletedTestCase) => {
                const deletedByImport = cloneTestCase(deletedTestCase)
                deletedByImport.testCaseChange = {
                    originalTestCase: cloneTestCase(deletedTestCase),
                    changeType: ChangeType.Deleted
                } as TestCaseChange
                importedTestCases.push(deletedByImport)
            })
            state.importedTestCases = importedTestCases
        },
        clearRoutingRuleSetImport: (state) => {
            state.importedRuleSet = {
                name: "",
                description: "",
                timestamp: "",
                version: 0,
                decisionRules: []
            }
        },
        clearTestCasesImport: (state) => {
            state.importedTestCases = []
        },
        acceptRoutingRuleSetImport: (state) => {
            //Imported rules with Deleted are actually copies of original rules that are not present in the import, they're only here for the comparison display
            const actualImports = state.importedRuleSet.decisionRules.filter(x => x.routingRuleChange?.changeType !== ChangeType.Deleted)
            //Imported rules with New could either be new or changed compared to the original. The New status was used to simplify the comparison display
            const actualImportsWithChanges = actualImports.map((importRule) => {
                const currentRule = state.routingRuleSet.decisionRules.find(x => x.id === importRule.id)
                if (!currentRule || importRule.routingRuleChange?.changeType !== ChangeType.New) {
                    //Actually new
                    return importRule
                }
                //Not really New, find the original values
                return {
                    ...importRule,
                    routingRuleChange: { changeType: ChangeType.Changed, originalRoutingRule: currentRule.routingRuleChange?.originalRoutingRule ?? deepCloneRoutingRule(currentRule) }
                }
            })
            state.routingRuleSet = {
                ...state.importedRuleSet,
                decisionRules: actualImportsWithChanges
            }
            state.importedRuleSet = {
                name: "",
                description: "",
                timestamp: "",
                version: 0,
                decisionRules: []
            }
        },
        acceptTestCasesImport: (state) => {
            state.testCases = [...state.importedTestCases]
            state.importedTestCases = []
        },
        upsertRoutingRule: (state, action) => {
            const newRule = action.payload;
            const ruleIsNew = state.routingRuleSet.decisionRules.find((stateRule) => stateRule.id === newRule.id) === undefined
            const needToReorderSequence = state.routingRuleSet.decisionRules.find((stateRule) =>
                stateRule.sequenceNumber === newRule.sequenceNumber && stateRule.id !== newRule.id && stateRule.routingRuleChange?.changeType !== ChangeType.Deleted) !== undefined
            const insertAtEnd = newRule.sequenceNumber < 0
            if (insertAtEnd) {
                //Calculate sequenceNumber for the rule to be upserted
                const maxSequenceNumber = Math.max(...state.routingRuleSet.decisionRules.map((r) => r.sequenceNumber))
                newRule.sequenceNumber = maxSequenceNumber + 1
            } else if (needToReorderSequence) {
                //Push all rules with a higher sequenceNumber down a spot to create room
                state.routingRuleSet.decisionRules = state.routingRuleSet.decisionRules.map((currentRule) => {
                    if (currentRule.sequenceNumber >= newRule.sequenceNumber && currentRule.id !== newRule.id && currentRule.routingRuleChange?.changeType !== ChangeType.Deleted) {
                        currentRule.sequenceNumber++
                    }
                    return currentRule;
                });
            }
            if (ruleIsNew) {
                //New rule will be inserted according to its sequenceNumber
                state.routingRuleSet.decisionRules.push(newRule)
            } else {
                //Replace current rule with new rule
                state.routingRuleSet.decisionRules = state.routingRuleSet.decisionRules.map((currentRule) => {
                    if (currentRule.id === newRule.id) {
                        return newRule;
                    }
                    return currentRule;
                });
            }
        },
        upsertTestCase: (state, action) => {
            const newTestCase = action.payload;
            const testCaseIsNew = state.testCases.find((stateTest) => stateTest.id === newTestCase.id) === undefined
            if (testCaseIsNew) {
                state.testCases.push(newTestCase)
            } else {
                //Replace current testcase with new testcase
                state.testCases = state.testCases.map((currentTest) => {
                    if (currentTest.id === newTestCase.id) {
                        return newTestCase;
                    }
                    return currentTest;
                });
            }
        },
        updateRuleSetDescription: (state, action) => {
            const description = action.payload
            state.routingRuleSet.description = description
        }
    }
});