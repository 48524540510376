import moment from "moment"

export const formatLocalDateTime = (dateTime: string) => {
    if (!dateTime) {
        return undefined
    }
    //Any date string, converted to local timezone, formatted to input-datetime-local format
    const dateTimeMoment = moment(dateTime)
    const formatted = dateTimeMoment.format('YYYY-MM-DDTHH:mm')
    return formatted
}

export const localDateTimeToIso = (dateTime?: string) => {
    if (!dateTime) {
        return undefined
    }
    //input-datetime-local format date string, formatted as ISO date, including conversion from local timezone
    const localDateTime = new Date(dateTime)
    const formatted = localDateTime.toISOString()
    return formatted
}
