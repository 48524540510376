import { Field, getIn, useFormikContext } from "formik"
import { Input, Select } from "../../../../_metronic/_partials/controls"
import { conditionDropdownOptions } from "../../models/RoutingRuleDropdownData"

const deliveryTypeOptions = conditionDropdownOptions.find(x => x.variableName === "DeliveryType")?.evaluationValues ?? []
const deliveryMethodOptions = conditionDropdownOptions.find(x => x.variableName === "DeliveryMethod")?.evaluationValues ?? []

const TestCaseAddressEditBlock = () => {
    const { values } = useFormikContext();

    return (
        <div className="border border-secondary">
            <p className="routing-rule-text-smallcaps ml-4 mt-4">Address</p>
            <div className="border-top border-secondary p-4">
                <div className="form-row">
                    <div className="form-group col-4 mb-3">
                        <label htmlFor="testcase-edit-receivername">Recipient Name</label>
                        <Field
                            component={Input}
                            id="testcase-edit-receivername"
                            name="testProduct.receiverName"
                            placeholder='e.g. Testing YPB'
                            type="string"
                            value={getIn(values, "testProduct.receiverName") ?? ''} //Make sure Input Component is never uncontrolled
                        />
                    </div>
                    <div className="form-group col-2 mb-3">
                        <label htmlFor="testcase-edit-postcode">Post Code</label>
                        <Field
                            component={Input}
                            id="testcase-edit-postcode"
                            name="testProduct.destinationPostCode"
                            type="string"
                            value={getIn(values, "testProduct.destinationPostCode") ?? ''} //Make sure Input Component is never uncontrolled
                        />
                    </div>
                    <div className="form-group col-2 mb-3">
                        <label htmlFor="testcase-edit-country">Country*</label>
                        <Field
                            component={Input}
                            id="testcase-edit-country"
                            name="testProduct.destinationCountry"
                            placeholder='e.g. NL'
                            type="string"
                        />
                    </div>
                </div>
            </div>
            <div className="form-row pl-4">
                <div className="form-group col-4">
                    <label htmlFor="testcase-edit-deliverymethod">Delivery Method</label>
                    <Field
                        component={Select}
                        id="testcase-edit-deliverymethod"
                        name="testProduct.deliveryMethod"
                    >
                        <option key={"select"} value="">select Delivery Method</option>
                        {deliveryMethodOptions.map(method => (
                            <option key={method} value={method}>{method}</option>
                        ))}
                    </Field>
                </div>
                <div className="form-group col-4">
                    <label htmlFor="testcase-edit-deliverytype">Delivery Type</label>
                    <Field
                        component={Select}
                        id="testcase-edit-deliverytype"
                        name="testProduct.deliveryType"
                    >
                        <option key={"select"} value="">select Delivery Type</option>
                        {deliveryTypeOptions.map(type => (
                            <option key={type} value={type}>{type}</option>
                        ))}
                    </Field>
                </div>
            </div>
        </div>
    )
}

export default TestCaseAddressEditBlock