import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls';
import { TableMode } from '../../models/Common';
import { useRoutingRuleContext } from '../../RoutingRuleContext';
import TestCasesActionCard from './TestCasesActionCard';
import TestCasesImportActionCard from './TestCasesImportActionCard';
import TestCasesTable from './TestCasesTable';

type Props = {
    cardProps?: any;
}

const TestCasesCard = (props: Props) => {
    const routingRulesContext = useRoutingRuleContext()
    const testCaseTableMode = (routingRulesContext.importedTestCases && routingRulesContext.importedTestCases.length > 0)
        ? TableMode.Import
        : TableMode.Normal

    return (
        <Card {...props.cardProps}>
            <CardHeader
                className='border-0'
                title="Routing Rule Test Cases"
                {...props.cardProps}
            >
                <CardHeaderToolbar {...props.cardProps}>
                    <button
                        type="button"
                        className="border-0 bg-transparent"
                        onClick={routingRulesContext.uiEvents.showRoutingRulesPage}>
                        <u>Show Routing Rules</u>
                    </button>
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody {...props.cardProps}>
                <div className='mb-6'>
                    {testCaseTableMode === TableMode.Normal && <TestCasesActionCard />}
                    {testCaseTableMode === TableMode.Import && <TestCasesImportActionCard />}
                </div>
                <TestCasesTable tableMode={testCaseTableMode} />
            </CardBody>
        </Card>
    );
}

export default TestCasesCard;