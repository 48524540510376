import BootstrapTable from "react-bootstrap-table-next";
import { useState, useEffect, ChangeEvent, useRef } from "react";
import { Card, CardHeader } from "../../../_metronic/_partials/controls";
import { Spinner } from "react-bootstrap";
import moment, { MomentInput } from "moment";
import ReactJson from 'react-json-view'
import { getDeliveryOptions, getRequestByOrderCode } from '../api/TestDeliveryOptions';
import './TestDeliveryOptionsPage.scss';
//delivery methods
import PudoIcon from '../icons/icon_pudo.png';
import HomeIcon from '../icons/icon_home.png';
//delivery types
import StandardDeliveryIcon from '../icons/icon_standard.png';
import FastDeliveryIcon from '../icons/icon_fast.png';
import ExpressDeliveryIcon from '../icons/icon_express.png';
import { isAvailableOnDevelopment } from "../../utils/IsAvailableOnFeatureFlag";

const exampleJson = `{
  "ChannelName": "photobox.co.uk",
  "Brand": "Photobox",
  "Locale": "GB",  
  "Address": {
    "CountryId": "GB",
    "Postcode": "EC1R 5EN",
    "ReceiverName": null
  },
  "OrderDate": null,
  "Products": [
    {
      "tag": "Mug",
      "articleCode": "PAP_720",
      "quantity": 1,
      "options": {
        "Pages": "1",
        "articleCode": "PAP_720"
      }
    },
    {
      "tag": "Cards",
      "articleCode": "PAP_940",
      "quantity": 10,
      "options": {
        "Pages": "10",
        "Paper": "Glossy",
        "articleCode": "PAP_940"
      }
    }
  ]
}`;

const TestDeliveryOptionsPage = (props: any) => {
  const [jsonInput, setJsonInput] = useState('');
  const [orderCode, setOrderCode] = useState('');
  const [data, setData] = useState<any | null>(null);
  const [traceabilityJsonObject, setTraceabilityJsonObject] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [copyMessage, setCopyMessage] = useState<string | null>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleGetOptions = async () => {
    setData(null);
    setIsLoading(true);
    setError(null);
    try {
      const result = await getDeliveryOptions(JSON.parse(jsonInput));
      setTraceabilityJsonObject(result);
      const resultOptions = result.flattenedDeliveryOptions.map(
        (option: any) =>
        ({ 
          factoryCode: option.factoryCode,
          shipmentNumber: option.shipmentNumber,
          rowSpan: option.rowSpan,
          products: option.products,
          optionNumber: option.optionNumber,
          shipDate: option.shipDate,
          deliveryDate: option.deliveryDate,
          deliveryMethod: option.deliveryMethod,
          deliveryType: option.deliveryType,
          carrierName: option.carrierName,
          iWaysCarrierCode: option.iWaysCarrierCode,
          pudoNetworkId: option.pudoNetworkId,
          deliveryMethodWithNetwork: { method: option.deliveryMethod, networkId: option.pudoNetworkId}
        }));
      setData(resultOptions);
    } catch (err: any) {
      let errorMessage = err.message || "An error occurred while getting the Delivery Options";
      const error = err?.response?.data?.Message;
      if (error) {
        errorMessage = err?.response?.data?.Message;
        try {
          let errorObj = JSON.parse(errorMessage);
          errorObj = JSON.parse(errorObj.Message);
          errorMessage = errorObj.Message;    
        } catch {}
      }
      else if (err?.response?.data){
        errorMessage = err?.response?.data;
      }

      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setJsonInput(e.target.value);
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
    }
  };

  const handleOrderCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOrderCode(e.target.value);
  };

  const handleGetJson = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getRequestByOrderCode(orderCode);
      setJsonInput(JSON.stringify(response, null, 2));      
    } catch (err: any) {
      setError(err?.response?.data?.Message || "An error occurred while fetching the Delivery Options request.");
    } finally {
      setIsLoading(false);
    }

    //get delivery options right away often ends unpredictably. For now, just clear whatever was previously loaded.
    //handleGetOptions();
    setData(null);
  };

  const handleCopyExampleJson = () => {
    setJsonInput(exampleJson);
    setCopyMessage('Example JSON has been copied to the input box');
    setTimeout(() => {
      setCopyMessage(null);
    }, 3000);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
    }
  }, [jsonInput]);
  
  const shipDateFormatter = (cell: MomentInput) => {
    if (!cell) {
      return "";
    }
    const shipDate = moment(cell).utc();
    const shippingCutoffTimeNotValid = shipDate.hour() === 0 && shipDate.minute() === 0;  //considering pickup time at midnight is not a real case
    const toolTip = shippingCutoffTimeNotValid ? "Shipping Cutoff Time not returned from Centiro. Could be wrong zipcode, or misconfiguration" : `Shipping Cutoff time: ${shipDate.format("HH:mm")}`;
    return <>{shipDate.format("YYYY-MM-DD")} <span className={shippingCutoffTimeNotValid ? "error" : "good"} title={toolTip}>{shipDate.format("HH:mm")}</span></>;
  };

  const deliveryDateFormatter = (cell: any, row: any) => {
    const deliveryDate = moment(cell);
    const shipDate = moment(row.shipDate);

    const dateDiff = deliveryDate.diff(shipDate, 'days');
    let className, tooltip;
    if (dateDiff < 0) {
      className = "error";
      tooltip = "DeliveryDate before Shipdate is wrong. Please check country+zipcode pair and/or shipping lead time calculation in Centiro."
    }
    else if (dateDiff > 10) {
      className = "warn";
      tooltip = "More than 10 days shipping lead time is suspicious. IF the given ZIP CODE is valid - you might want to check lead time calculation in Centiro."

    }
    return <span className={className} title={tooltip}>{cell}</span>
  }

  const productsFormatter = (cell: any, row: any) => {
    //consider rowSpan, return empty if rowSpan == ""
    if (row.rowSpan === "") {
      return <></>;
    }

    //cell is array of products
    return cell.map((p:any) => {
      return (<><span className={(p.skuFound ? "good" : "error")} title={p.skuFound ? `SKU: ${p.sku}` : "SKU not found. Please product options in the Request and/or SKU Mapper."}>{p.quantity}x {p.productTag}</span><br/></>);
    });
  }

  const deliveryOptionFormatter = (cell: any, row: any) => {    
    const validCarrierCode = row.iWaysCarrierCode?.length > 0;
    const deliveryType = row.deliveryType.toLowerCase();
    let deliveryTypeIcon;
    switch (deliveryType) {
      case "express":
        deliveryTypeIcon = ExpressDeliveryIcon;
        break;
      case "fast":
        deliveryTypeIcon = FastDeliveryIcon;
        break;
      case "standard":
        deliveryTypeIcon = StandardDeliveryIcon;
        break;
      default:
        console.error(`Unsupported deliveryType: ${deliveryType}`);
        deliveryTypeIcon = "";
    }
    
    const deliveryMethod = row.deliveryMethod.toLowerCase();

    const pudoNetworkNotSet = row.pudoNetworkId == "*" || row.pudoNetworkId == "";
    const pudoNetworkMsg = pudoNetworkNotSet ? `PUDO NetworkId '${row.pudoNetworkId}' IS NOT VALID. Please set in Centiro Candidates` : `PUDO network: ${row.pudoNetworkId}`;
    const carrierCodeMsg = validCarrierCode ? `iWays carrier code: ${row.iWaysCarrierCode}` : "iWays Carrier code NOT SET. Please set in Centiro Candidates.";

    if (deliveryMethod === "pudo") {     
      return (<>
        <img src={deliveryTypeIcon} title={row.deliveryType} className="deliveryIcon" />        
        <img src={PudoIcon} title={pudoNetworkMsg} className="deliveryIcon" />
        <br/>        
        <span className={(pudoNetworkNotSet || !validCarrierCode ? "error" : "good")} title={carrierCodeMsg + "\r\n" + pudoNetworkMsg}>{row.carrierName}</span>
      </>);
    }
    else if (deliveryMethod === "home") {
      return (<>
        <img src={deliveryTypeIcon} title={row.deliveryType} className="deliveryIcon" /> 
        <img src={HomeIcon} title={row.deliveryMethod} alt="HOME" className="deliveryIcon" />
        <br/>        
        <span className={(validCarrierCode ? "good" : "error")} title={carrierCodeMsg}>{row.carrierName}</span>
      </>);
    }
    return `${row.deliveryType} ${row.deliveryMethod} ${row.carrierName} (${row.iWaysCarrierCode}, ${row.pudoNetworkId})`;
  }

  const rowSpanFormatter = (cell: any, row: any) => {
    //hide value if rowSpan == ""
    if (row.rowSpan === "") {
      return <></>;
    }
    
    return <b>{cell}</b>;
  }

  //"custom stripes" for "rowspan"
  const styleStripeFormatter = (
    cell: any,
    row: any,
    rowIndex: any,
    colIndex: any
  ) => {
    return {
      backgroundColor: row.shipmentNumber % 2 === 0 ? '#FFFFFF' : '#EBEDF3',
      //separate packages with border - but top-border doesn't work for some reason, todo
      //borderTop: (row.rowSpan === "" ? '' : '1px solid rgba(0, 0, 0, 0.8)')
    };
  };

  const columns = [
    {
      dataField: "factoryCode",
      text: "Plant",
      classes: "text-left",
      headerClasses: "text-left",
      formatter: rowSpanFormatter,
      style: styleStripeFormatter
    },
    {
      dataField: "shipmentNumber",
      text: "PKG#",
      classes: "text-left",
      headerClasses: "text-left",
      formatter: rowSpanFormatter,
      style: styleStripeFormatter
    },
    {
      dataField: "products",
      text: "Products",
      classes: "text-left",
      headerClasses: "text-left",
      formatter: productsFormatter,
      style: styleStripeFormatter
    },
    {
      dataField: "optionNumber",
      text: "OPT#",
      classes: "text-left",
      headerClasses: "text-left",
    },
    {
      dataField: "shipDate",
      text: "Ship Date",
      classes: "text-left",
      headerClasses: "text-left",
      formatter: shipDateFormatter
    },
    {
      dataField: "deliveryDate",
      text: "Delivery Date",
      classes: "text-left",
      headerClasses: "text-left",
      formatter: deliveryDateFormatter
    },
    {
      dataField: "carrierName",
      text: "Delivery Option",
      classes: "text-left",
      headerClasses: "text-left",
      formatter: deliveryOptionFormatter
    }
  ];

  return (
    <>      
      <div className="row">
        <div className="col-md-4">
          <Card {...props}>
            <CardHeader title="INPUT" {...props}></CardHeader>
            <div style={{ padding: '10px' }}>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="SAL order code (optional)"
                  value={orderCode}
                  onChange={handleOrderCodeChange}
                />
                <button
                  type="button"
                  className="btn btn-primary ml-2"
                  onClick={handleGetJson}
                >
                  Get Request
                </button>
              </div>
              <div className="input-group mb-3">
                <div className="d-flex w-100">
                  <button
                    onClick={handleCopyExampleJson}
                    type="button"
                    className="btn btn-secondary mr-2"
                    style={{ flex: 1 }}
                  >
                    Paste Example
                  </button>
                  <button
                    onClick={handleGetOptions}
                    type="button"
                    className="btn btn-success"
                    style={{ flex: 1 }}
                  >
                    Get Options
                  </button>
                </div>
              </div>
              <textarea
                ref={inputRef}
                value={jsonInput}
                onChange={handleInputChange}
                className="form-control mb-3"
                rows={10}
                placeholder="Click Paste Example button to get working example ... or write/paste your own Delivery Options API request"
                style={{ padding: '10px', fontSize: '11px', overflow: 'hidden', fontFamily: 'courier' }}
              ></textarea>
              {copyMessage && <div className="mt-2 text-success">{copyMessage}</div>}
            </div>
          </Card>
        </div>

        <div className="col-md-8">
          <Card {...props}>
            <CardHeader title="DELIVERY OPTIONS" {...props}></CardHeader>
            {isLoading ? (
              <div className="text-center mt-5">
                <Spinner animation="border" role="status" variant="primary"></Spinner>
                <div>Calculating...</div>
              </div>
            ) : (
              <>
                {data ? (
                  <div className="p-10">
                    <BootstrapTable
                      wrapperClasses="table-responsive column-width-control"
                      classes="table table-head-custom table-vertical-center overflow-hidden"
                      bootstrap4
                      bordered={true}
                      striped={true}
                      remote
                      keyField="productGroup"
                      data={data}
                      columns={columns}
                      defaultSorted={[{ dataField: "productGroup", order: "asc" }]}
                    ></BootstrapTable>            
                  </div>
                ) : (
                  <div className="text-left pl-10 pr-10 alert-info">
                    <p>No delivery options have been found yet.</p>
                    <p> Ways to <b>get</b> them:
                      <ol>
                        <li>By <b>OrderCode</b>: Paste order code, and click <b className="text-primary">Get Request</b>. This will fetch order details, and build corresponding DeliveryOptions request, then click <b className="text-success">Get Options</b></li>
                        <li>By <b>Request</b>: Click <b className="text-muted">Paste Example</b> button to get an example request, then click <b className="text-success">Get Options</b>. Feel free to change parameters in the request as needed.</li>                        
                      </ol>
                    </p>
                    <p>See below the quick guide for request parameters.</p>
                    <p>
                      Please reach out to <b>#plant-tech-support</b> if you have questions, suggestions or something is not working as expected.
                    </p>                    
                  </div>
                )}
              </>
            )}
          </Card>
          {isAvailableOnDevelopment && traceabilityJsonObject && <Card {...props}>
            <CardHeader title="DELIVERY OPTIONS TRACEABILITY" {...props}></CardHeader>
            {isLoading ? (
              <div className="text-center mt-5">
                <Spinner animation="border" role="status" variant="primary"></Spinner>
                <div>Calculating...</div>
              </div>
            ) : (
              <>
                <div className="p-5">
                  <ReactJson src={traceabilityJsonObject} theme="shapeshifter:inverted" name="Delivery Options Traceability" displayDataTypes={false} displayObjectSize={false} collapsed={1} />
                </div>
              </>
            )}
          </Card>}
          {error && (
            <div className="alert alert-danger mt-3" role="alert">
              {error}
            </div>
          )}
          <div className="p-5 alert-info">
            <p>Quick guide for the <b>Request parameters</b>:
              <ul id="collapsibleContent">
                <li><b>ChannelName</b>: photobox.fr / photobox.co.uk / hofmann.es / albelli.nl / etc as they are known in Ecom. Used for routing to correct production site. <b>Required</b></li>
                <li><b>Brand</b>: corresponds to  the channel name without locale (e.g photobox, hofmann, albelli). Could be used in carrier selection rules to limit delivery options to certain brands. <b>Required</b></li>
                <li><b>Address</b>: delivery address of the order.
                  <ul>
                    <li><b>Country is required</b></li>
                    <li>(optional) <b>Postcode</b> will be considered in carrier selection and shipping lead time.</li>
                    <li>(optional) <b>Name</b> in the format "Testing <span className="keyWord">CODE</span>", where <span className="keyWord">CODE</span> is one of the known production site codes (as visible in the MyFactory UI - KHS, PAT, ELA, JONDO etc.)
                      This allows to override routing rules, and direct order to the specified factory.</li>
                  </ul>                          
                </li>
                <li><b>Products</b>: array of products, each having a tag, product code, quantity and set of options
                  <ul>
                    <li><b>Tag</b>: is a simple identifier of a product (single unique letter is OK) that will be echoed in package contents. Allows to identify how products are allocated between factories and packages</li>
                    <li><b>Article Code</b>: (PAP number) is a generic identifier of a product. Together with set of options, it allows to map to SKU</li>
                    <li><b>Options</b>: (set of names and values) allow to specify configuration of an ordered product. Must match with what is setup in Ecom catalog, as well as in Routing Rules and Packaging Calculator)
                        <b> Pages: 1</b> is the bare minimum required option, that is valid for most of the products.
                    </li>
                  </ul>
                </li>
              </ul>      
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestDeliveryOptionsPage;
