import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls';
import { useDispatch } from "react-redux";
import * as actions from '../../redux/RoutingRulesActions'
import { useRoutingRuleContext } from '../../RoutingRuleContext';

type Props = {
    cardProps?: any;
}

const TestCasesSaveCard = (props: Props) => {
    const dispatch = useDispatch();
    const routingRuleContext = useRoutingRuleContext();

    const changedTestCases = routingRuleContext.changedTestCases
    const someChanges = changedTestCases.length > 0
    const nrApiCallsInProgress = routingRuleContext.nrApiCallsInProgress
    const importInProgress = routingRuleContext.importedTestCases && routingRuleContext.importedTestCases.length > 0

    return (
        <>
            {
                someChanges && !importInProgress &&
                <Card
                    className="border border-primary routing-rules-changed mx-2 col"
                    {...props.cardProps}
                >
                    <CardHeader
                        className="border-0"
                        {...props.cardProps}
                    >
                        <h3 className='card-title card-label text-primary'>Unsaved Test Changes</h3>
                    </CardHeader>
                    <CardBody
                        className="d-flex flex-column justify-content-end"
                        {...props.cardProps}
                    >
                        {(nrApiCallsInProgress > 0) &&
                            <div className='d-flex justify-content-center'>
                                <div className="overlay-layer bg-transparent">
                                    <div className="spinner spinner-lg spinner-success" />
                                </div>
                            </div>
                        }
                        <div className="d-flex align-items-end row">
                            <div className='d-flex align-items-baseline col-7'>
                                <div>You have</div>
                                <h3 className='px-2'>{changedTestCases.length}</h3>
                                <div>unsaved change(s)</div>
                            </div>
                            <button
                                type="button"
                                onClick={() => { dispatch(actions.fetchTestCases()) }}
                                className='btn btn-outline-theme border-2 fw-bold mx-1 col'>
                                Discard Changes
                            </button>
                            <button
                                type="submit"
                                onClick={() => { dispatch(actions.persistTestCases(routingRuleContext.availableTestCases)) }}
                                className='btn btn-primary-theme border-2 fw-bold mx-1 col'>
                                Save TestSuite
                            </button>
                        </div>
                    </CardBody>
                </Card>
            }
        </>
    )
}

export default TestCasesSaveCard