import { TestCase } from "../models/TestSuiteModel";

type Line = string[]

type ProductOption = {
    id: string,
    options: {
        option: string,
        optionValue: string
    }[]
}

type DirectlyWritable = { [key: string]: string }

const testResultColumnHeaders = ["selectedProductionSite"]
const requiredColumnHeaders = ["targetProductionSite", "id", "channelName", "destinationCountry", "articleCode"]
const optionalColumnHeaders = ["receiverName", "destinationPostCode", "deliveryMethod", "deliveryType", "simulatedEvaluationDate", "simulatedRandomValue"]
const fixedColumnHeaders = [...testResultColumnHeaders, ...requiredColumnHeaders, ...optionalColumnHeaders]

export const convertTestCasesToCSVData = (testCases: TestCase[]) => {
    //ProductOptions on the testProduct are effectively a dictionary. Here it is remapped for easier access to both keys and values
    const productOptions = testCases.map((test) => {
        return {
            id: test.id,
            options: Object.entries(test.testProduct.productOptions)
                .map((kvp) => {
                    return {
                        option: kvp[0],
                        optionValue: kvp[1]
                    }
                })
        } as ProductOption
    })

    //Find all ProductOptions used in at least 1 test case, and create columns for them
    const productOptionHeaders = productOptions
        .flatMap((po) => po.options.map((option) => option.option))
        .filter((value, index, array) => array.indexOf(value) === index)
        .sort();

    const lines = [] as Line[]
    lines.push([...fixedColumnHeaders, ...productOptionHeaders])

    //WARNING: the below PUSH actions need to stay in the same order as the header declarations!!!!
    testCases.forEach((test) => {
        const line = [] as Line

        //add TestResult fields
        line.push(test.testResult?.selectedProductionSite ?? test.testResult?.failureReason ?? "")

        //add required fields
        line.push(test.targetProductionSite)
        line.push(test.id)
        line.push(test.testProduct.channelName)
        line.push(test.testProduct.destinationCountry)
        line.push(test.testProduct.articleCode)

        //add optional fields
        line.push(test.testProduct.receiverName ?? "")
        line.push(test.testProduct.destinationPostCode ?? "")
        line.push(test.testProduct.deliveryMethod ?? "")
        line.push(test.testProduct.deliveryType ?? "")
        line.push(test.testContext?.simulatedEvaluationDate ?? "")
        line.push((test.testContext?.simulatedRandomValue) ? test.testContext.simulatedRandomValue.toString() : "")

        //add dynamic ProductOption fields
        const option = productOptions.find(x => x.id === test.id)
        productOptionHeaders.forEach((header) => {
            const optionValue = (option) ? (option.options.find(x => x.option === header)?.optionValue ?? "") : ""
            line.push(optionValue)
        })

        lines.push(line)
    })

    return lines
}

export const convertCSVDataToTestCases = (data: string) => {
    const dataLines = data.split("\r\n").filter((line) => line !== "" && line.replace(",", "") !== "")
    if (dataLines.length < 2) {
        throw new SyntaxError(`Not enough lines present, there should be at least one header and one test case line`)
    }

    const headers = dataLines[0].split(",")
    const lines = dataLines.slice(1).map((line) => line.split(",")) as Line[]
    const productOptionHeaders = headers.filter((header) =>
        fixedColumnHeaders.findIndex(x => x.toLowerCase() === header.toLowerCase()) === -1
    )
    const getColumnValue = (header: string, line: Line) => {
        var colIndex = headers.findIndex(x => header.toLowerCase() === x.toLowerCase())
        return (colIndex >= 0) ? line[colIndex] : undefined
    }

    const missingHeaders = requiredColumnHeaders.filter((req) => headers.findIndex(x => x.toLowerCase() === req.toLowerCase()) === -1)
    if (missingHeaders.length > 0) {
        throw new SyntaxError(`Missing required headers ${missingHeaders.join(', ')}`)
    }

    if (dataLines.some(line => line.length < requiredColumnHeaders.length)) {
        throw new SyntaxError(`Not all lines contain enough columns. Possible cause: columns are not separated by commas`)
    }

    const testCases = lines.map((line) => {
        const testCase = {
            id: getColumnValue("id", line),
            targetProductionSite: getColumnValue("targetProductionSite", line),
            testProduct: {
                channelName: getColumnValue("channelName", line),
                receiverName: getColumnValue("receiverName", line),
                destinationCountry: getColumnValue("destinationCountry", line),
                destinationPostCode: getColumnValue("destinationPostCode", line),
                articleCode: getColumnValue("articleCode", line),
                deliveryMethod: getColumnValue("deliveryMethod", line),
                deliveryType: getColumnValue("deliveryType", line),
                productOptions: {
                    //empty for now, will write properties directly in here bypassing any typing
                }
            },
            testContext: {
                simulatedRandomValue: getColumnValue("simulatedRandomValue", line) ? Number(getColumnValue("simulatedRandomValue", line)) : undefined,
                simulatedEvaluationDate: getColumnValue("simulatedEvaluationDate", line)
            }
        } as TestCase

        //We're explicitly updating the productOption with dynamic properties in-place in the testCase
        const directlyWritable = testCase.testProduct.productOptions as DirectlyWritable
        productOptionHeaders.forEach((header) => {
            const columnValue = getColumnValue(header, line)
            if (columnValue) {
                directlyWritable[header] = columnValue
            }
        })

        return testCase
    })

    return testCases
}