import { activityRuleTypes, conditionTypes, ensureFactoryWeightsArePercentage, ICondition, IRoutingRule } from "../models/RoutingRuleSetModel"
import { localDateTimeToIso } from "./DateTimeHelpers"

export type Match = {
    incomingIndex: number,
    currentIndex: number,
    foundMatch: boolean
}

//compare entries in two separate arrays for equality, starting at lastMatch, and return the next match found
export const findNextMatch = (lastMatch: Match, incomingRoutingRuleHashes: number[], currentRoutingRuleHashes: number[]) => {
    let match = {
        //initialize with NotFound, by using length, we're actually pointing past the end of the array
        incomingIndex: incomingRoutingRuleHashes.length,
        currentIndex: currentRoutingRuleHashes.length,
        foundMatch: false
    } as Match

    for (let incomingIndex = lastMatch.incomingIndex + 1; incomingIndex < incomingRoutingRuleHashes.length; incomingIndex++) {
        var matchedCurrent = currentRoutingRuleHashes.findIndex((currentHash, currentIndex) =>
            currentIndex > lastMatch.currentIndex && incomingRoutingRuleHashes[incomingIndex] === currentHash)
        if (matchedCurrent !== -1) {
            return {
                incomingIndex: incomingIndex,
                currentIndex: matchedCurrent,
                foundMatch: true
            } as Match
        }
    }
    return match
}

//Borrowed hash function. Don't ask me how it works.
const cyrb53 = (str: string, seed = 0) => {
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

//function will take all parts of a RoutingRule that are actually relevant for its functioning, and format them in consistent way
//in the end, if the rule will behave in the same way as another, it should have the same hash
export const getRoutingRuleHash = (rule?: IRoutingRule) => {
    if (!rule) {
        return 0
    }

    const normalizedRoutingRuleData = [] as String[]
    normalizedRoutingRuleData.push(rule.name.trim().toLowerCase())

    //add a string for each condition, containing f.ex "AND ArticleType equals Mug", in a predictable sort order
    normalizedRoutingRuleData.push((rule.conditions.logicalOperator ?? 'NoOperator'))
    getNormalizedConditionStrings(rule.conditions)?.forEach(x => normalizedRoutingRuleData.push(x))
    //add a string for each conditionGroup, containing all conditions in it, in a predictable sort order
    const normalizedConditionGroupStrings = rule.conditions.conditions?.filter(x => x.conditionType === conditionTypes.conditionGroup)
        .map(x => `${x.logicalOperator}-${getNormalizedConditionStrings(x)?.join('-')}`)
        .sort()
    normalizedConditionGroupStrings?.forEach(x => normalizedRoutingRuleData.push(x))

    //InActive/Active can be achieved in several ways
    const unconditionalActivityRule = rule.activityRules?.find(x => x.activityRuleType === activityRuleTypes.unconditionalActivationRule)
    const isInActive = unconditionalActivityRule !== undefined && unconditionalActivityRule.active === false
    normalizedRoutingRuleData.push(isInActive ? "InActive" : "Active")

    //Activity DateRange presence & absence of either the whole rule or one of the fields, condensed to a single string
    const activeBetweenDatesRule = rule.activityRules?.find(x => x.activityRuleType === activityRuleTypes.activeBetweenDatesRule)
    const dateFrom = localDateTimeToIso(activeBetweenDatesRule?.activeFrom) ?? "NoDateFrom"
    const dateUntil = localDateTimeToIso(activeBetweenDatesRule?.activeUntil) ?? "NoDateUntil"
    normalizedRoutingRuleData.push(`${dateFrom}-${dateUntil}`)

    //The factory spread is mapped to a percentage before creating a normalized value for it because weigh 1/1 is equal to weight 2/2 or 50/50
    const normalizedDecision = ensureFactoryWeightsArePercentage(rule)
    const normalizedDecisionStrings = normalizedDecision.map(x => `${x.productionSite.trim().toLocaleLowerCase}-${x.weight}`).sort()
    normalizedDecisionStrings.forEach(x => normalizedRoutingRuleData.push(x))

    const hash = cyrb53(normalizedRoutingRuleData.join(';'))
    return hash
}

const getNormalizedConditionStrings = (conditionGroup: ICondition) => {
    const normalizedConditionStrings = conditionGroup.conditions?.filter(x => x.conditionType === conditionTypes.condition)
        .map(x => `${x.variableName?.trim().toLowerCase()}-${x.conditionOperator?.trim().toLowerCase()}-${x.evaluationValues?.map(y => y.trim().toLowerCase()).sort().join("-")}`)
        .sort()

    return normalizedConditionStrings
}
