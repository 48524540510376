import { Field, FieldArray, getIn, useFormikContext } from "formik"
import TestCaseProductOptionEditRow from "./TestCaseProductOptionEditRow"
import { Select } from "../../../../_metronic/_partials/controls"
import { useRoutingRuleContext } from "../../RoutingRuleContext";
import { EditTestCase } from "./TestCaseEditModal";

const TestCaseProductEditBlock = () => {
    const routingRulesContext = useRoutingRuleContext();
    const { setFieldTouched, setFieldValue, values } = useFormikContext()
    const editTestCase = values as EditTestCase
    const articleTypes = routingRulesContext.articleTypes;
    const articleType = articleTypes.find(at => at.name === getIn(values, "articleType"))
    return (
        <div className="border border-secondary mt-6">
            <p className="routing-rule-text-smallcaps ml-4 mt-4">Product</p>
            <div className="border-top border-secondary p-4">
                <div className="form-row">
                    <div className="form-group col-4 mb-3">
                        <label htmlFor="testcase-edit-articleType">Product Type*</label>
                        <Field
                            component={Select}
                            id="testcase-edit-articleType"
                            name="articleType"
                            onChange={(event: any) => {
                                setFieldValue("articleType", event.target.value)
                                setFieldTouched("articleType", true)
                                //clear articleCode, old selection won't be valid for this ArticleType
                                setFieldValue("testProduct.articleCode", "")
                                setFieldTouched("testProduct.articleCode", true)
                            }}
                        >
                            <option key={"select"} value="">select Product Type</option>
                            {articleTypes.map(at => (
                                <option key={at.name} value={at.name}>{at.name}</option>
                            ))}
                        </Field>
                    </div>
                    <div className="form-group col-4 mb-3">
                        <label htmlFor="testcase-edit-articleCode">Product Code*</label>
                        <Field
                            component={Select}
                            id="testcase-edit-articleCode"
                            name="testProduct.articleCode"
                        >
                            {!articleType && <option key={"select"} value="">select Product Type first</option>}
                            {articleType && <option key={"select"} value="">select Product Code</option>}
                            {articleType && articleType.articles.map(article => (
                                <option key={article} value={article}>{article}</option>
                            ))}
                        </Field>
                    </div>
                </div>
            </div>
            <div className="pl-4 pb-3">Product Options</div>
            <FieldArray name="productOptionsArray">
                {({ push: addProductOption, remove: removeProductOption }) => {
                    return (
                        <div>
                            {editTestCase.productOptionsArray.map((_productOption, index) => (
                                <TestCaseProductOptionEditRow
                                    productOptionFieldName={`productOptionsArray[${index}]`}
                                    onDeleteRow={() => removeProductOption(index)}
                                    key={index}
                                />
                            )
                            )}
                            <button
                                type="button"
                                className="text-primary border-0 bg-transparent ml-3 mb-3"
                                onClick={() => addProductOption({
                                    option: "",
                                    optionValue: ""
                                })}>
                                <u>Add product option</u>
                            </button>
                        </div>
                    )
                }}
            </FieldArray>
        </div>
    )
}

export default TestCaseProductEditBlock