import { ChangeType } from "./Common"

export interface IRoutingRuleSet {
    name: string,
    description: string,
    timestamp: string,
    version: number,
    decisionRules: IRoutingRule[]
}

export interface IRoutingRule {
    id: string,
    name: string,
    description?: string,
    sequenceNumber: number
    activityRules?: IActivityRule[],
    conditions: ICondition,
    decision: IProductionSiteSelectionData[],
    routingRuleChange?: RoutingRuleChange //For internal processing only, not part of API contract
}

export interface IActivityRule {
    activityRuleType: string,
    activeFrom?: string, //activeBetweenDatesRule
    activeUntil?: string, //activeBetweenDatesRule
    active?: boolean, //unconditionalActivationRule
    activeWeekDays?: string[] //activeOnDayOfWeekRule
}

export const activityRuleTypes = {
    unconditionalActivationRule: 'unconditionalActivationRule',
    activeBetweenDatesRule: 'activeBetweenDatesRule',
    activeOnDayOfWeekRule: 'activeOnDayOfWeekRule'
}

export interface ICondition {
    conditionType: string,
    logicalOperator?: string, //conditionGroup
    conditions?: ICondition[], //conditionGroup 
    variableName?: string, //condition
    conditionOperator?: string, //condition
    evaluationValues?: string[] //condition
}

export const conditionTypes = {
    conditionGroup: 'conditionGroup',
    condition: 'condition'
}

export const logicalOperators = {
    and: 'and',
    or: 'or'
}

export const conditionOperators = {
    equal: 'equal',
    equalOrGreater: 'equalOrGreater',
    equalOrLesser: 'equalOrLesser',
    in: 'in',
    isSubsetOf: 'isSubsetOf',
    isAny: 'isAny'
}

export interface IProductionSiteSelectionData {
    productionSite: string,
    weight: number
}

export type RoutingRuleChange = {
    changeType: ChangeType,
    originalRoutingRule?: IRoutingRule
}

export const deepCloneConditionGroup = (conditionGroup: ICondition): ICondition => {
    return {
        ...conditionGroup,
        conditions: conditionGroup?.conditions?.map((c) => {
            return (c.conditionType === conditionTypes.conditionGroup) ?
                deepCloneConditionGroup(c) :
                { ...c }
        })
    } as ICondition
}

export const deepCloneRoutingRule = (rule: IRoutingRule): IRoutingRule => {
    return {
        ...rule,
        activityRules: rule.activityRules?.map((ar) => { return { ...ar } }),
        conditions: deepCloneConditionGroup(rule.conditions),
        decision: rule.decision?.map((psd) => { return { ...psd } })
    }
}

export const ensureFactoryWeightsArePercentage = (rule?: IRoutingRule) => {
    let productionSiteSelectionData = [] as IProductionSiteSelectionData[]
    if (rule?.decision) {
        const totalWeight = rule?.decision?.map((site) => site.weight).reduce(function (a, b) { return a + b; }, 0);
        productionSiteSelectionData = rule?.decision?.map((site) => ({
            productionSite: site.productionSite,
            weight: Math.round((site.weight / totalWeight) * 100)
        }))
    }
    return productionSiteSelectionData
}

//For saving/exporting or test runs, we need a ruleset without Deleted rules, and without internal changelog (routingRuleChange)
export const cleanRoutingRuleSetForPersistence = (ruleSet: IRoutingRuleSet): IRoutingRuleSet => {
    return {
        ...ruleSet,
        decisionRules: ruleSet.decisionRules
            .filter(x => x.routingRuleChange?.changeType !== ChangeType.Deleted)
            .map(x => {
                return {
                    ...x,
                    routingRuleChange: undefined
                } as IRoutingRule
            })
    } as IRoutingRuleSet
}

