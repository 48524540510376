
interface ExportCSVProps {
    data: any[]
    fileName: string
    className?: string
    children?: any
}

const ExportCSV: React.FC<ExportCSVProps> = ({ data, fileName, className, children }) => {
    const downloadCSV = () => {
        // Convert the data array into a CSV string
        const csvString =
            data.map(row => row.join(","))
                .join("\r\n");

        // Create a Blob from the CSV string
        const blob = new Blob([csvString], { type: 'text/csv' });

        // Generate a download link and initiate the download
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName || `download.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    return <button
        className={className ?? ""}
        onClick={downloadCSV}
        onMouseDown={e => e.preventDefault()}
    >
        {children ?? "Export CSV"}
    </button>;
};

export default ExportCSV;