export enum ChangeType {
    Changed = "changed",
    New = "new",
    Deleted = "deleted"
}

export enum TableMode {
    Normal = "normal",
    Import = "import",
    Compare = "compare"
}
