import { useDispatch } from 'react-redux';
import * as actions from '../../redux/RoutingRulesActions'
import { activityRuleTypes, deepCloneRoutingRule, IRoutingRule } from "../../models/RoutingRuleSetModel";
import BootstrapTable, { SortOrder } from "react-bootstrap-table-next";
import ExpandIcon from "../../../svg/ExpandIcon";
import moment from "moment";
import EditIconWithCopy from "../../../svg/EditIconWithCopy";
import CloneIconWithCopy from "../../../svg/CloneIconWithCopy";
import ResetIconWithCopy from "../../../svg/ResetIconWithCopy";
import ActiveIconWithCopy from "../../../svg/ActiveIconWithCopy";
import { SelectionReason, useRoutingRuleContext } from "../../RoutingRuleContext";
import { ChangeType, TableMode } from '../../models/Common';

type Props = {
    tableMode: TableMode
}

const getActiveToggle = (row: IRoutingRule) => {
    //Multiple ways of determining activity exist in the activityRules, get the one that contains the simple toggle
    const activeToggle = row.activityRules?.find((rule) => rule.activityRuleType === activityRuleTypes.unconditionalActivationRule)
    return activeToggle
}

const getDateRange = (row: IRoutingRule) => {
    //Multiple ways of determining activity exist in the activityRules, get the one that contains the date range
    const dateRange = row.activityRules?.find((rule) => rule.activityRuleType === activityRuleTypes.activeBetweenDatesRule)
    return dateRange
}

const isActive = (row: IRoutingRule) => {
    const activeToggle = getActiveToggle(row)
    return !activeToggle || activeToggle.active === true
}

const RoutingRulesTable = ({ tableMode }: Props) => {
    const dispatch = useDispatch();
    const routingRulesContext = useRoutingRuleContext();
    const nrApiCallsInProgress = routingRulesContext.nrApiCallsInProgress

    let decisionRules = [] as IRoutingRule[]
    if (tableMode === TableMode.Normal) {
        decisionRules = routingRulesContext.availableRoutingRules
    } else if (tableMode === TableMode.Import) {
        decisionRules = routingRulesContext.importedRuleSet?.decisionRules ?? []
    }

    const handleShowRuleDetails = (rule: IRoutingRule) => {
        routingRulesContext.setSelectedRoutingRule({ routingRule: rule, selectionReason: SelectionReason.Default });
        routingRulesContext.uiEvents.showRuleDetailsModal()
    }

    const handleShowRuleEditModal = (rule: IRoutingRule) => {
        routingRulesContext.setSelectedRoutingRule({ routingRule: rule, selectionReason: SelectionReason.Default });
        routingRulesContext.uiEvents.showRuleEditModal()
    }

    const handleCloneClick = (rule: IRoutingRule) => {
        const clonedRule = deepCloneRoutingRule(rule)
        clonedRule.id = crypto.randomUUID()
        clonedRule.name = "Clone of " + rule.name
        clonedRule.sequenceNumber = rule.sequenceNumber + 1
        routingRulesContext.setSelectedRoutingRule({ routingRule: clonedRule, selectionReason: SelectionReason.Clone });
        routingRulesContext.uiEvents.showRuleEditModal()
    }

    const handleResetClick = (rule: IRoutingRule) => {
        if (!rule.routingRuleChange) {
            return;
        }
        if (rule.routingRuleChange?.changeType === ChangeType.New) {
            const ruleCopy = deepCloneRoutingRule(rule)
            ruleCopy.routingRuleChange = { changeType: ChangeType.Deleted }
            dispatch(actions.upsertRoutingRule(ruleCopy))
        }
        if (rule.routingRuleChange.originalRoutingRule) {
            dispatch(actions.upsertRoutingRule(rule.routingRuleChange.originalRoutingRule))
        }
    }

    const handleActiveToggleClick = (rule: IRoutingRule) => {
        const editableRule = deepCloneRoutingRule(rule)
        if (!editableRule.activityRules) {
            editableRule.activityRules = []
        }
        let activeToggle = getActiveToggle(editableRule)
        if (!activeToggle) {
            activeToggle = {
                activityRuleType: activityRuleTypes.unconditionalActivationRule,
                active: true //if it doesn't exist yet, the toggle is interpreted as True
            }
            editableRule.activityRules.push(activeToggle)
        }
        activeToggle.active = (activeToggle.active) ? false : true
        if (!editableRule.routingRuleChange) {
            editableRule.routingRuleChange = { changeType: ChangeType.Changed, originalRoutingRule: deepCloneRoutingRule(rule) }
        }
        dispatch(actions.upsertRoutingRule(editableRule))
    }

    const getResetButtonVisibilityHeader = (rule: IRoutingRule) => {
        return (rule.routingRuleChange?.changeType === ChangeType.New || rule.routingRuleChange?.changeType === ChangeType.Changed)
            ? ''
            : ' visibility-hidden'
    }

    let setRowBackground = (rule: IRoutingRule): string => { return "" }
    if (tableMode === TableMode.Normal) {
        setRowBackground = (rule: IRoutingRule) => {
            let classes = '';

            if (rule.routingRuleChange) {
                classes = 'routing-rules-changed';
            }

            return classes;
        };
    } else if (tableMode === TableMode.Import) {
        setRowBackground = (rule: IRoutingRule) => {
            let classes = '';

            if (rule.routingRuleChange?.changeType === ChangeType.New) {
                classes = 'routing-rules-test-success'
            }
            if (rule.routingRuleChange?.changeType === ChangeType.Changed) {
                classes = 'routing-rules-changed'
            }
            if (rule.routingRuleChange?.changeType === ChangeType.Deleted) {
                classes = 'routing-rules-test-failed'
            }
            return classes;
        }
    }

    const columns = [
        {
            dataField: 'sequenceNumber',
            text: 'Priority',
            sort: tableMode === TableMode.Normal,
            //an explicit number sort rule is required, otherwise sorting is alphabetic
            sortFunc: (a: any, b: any, order: SortOrder) => {
                if (order === 'desc') {
                    return Number(b) - Number(a);
                } else {
                    return Number(a) - Number(b);
                }
            },
            classes: 'width-set-10',
        },
        {
            dataField: 'name',
            text: 'Name',
            sort: false,
            classes: 'width-set-25',
        },
        {
            dataField: 'productionSite',
            text: 'Factory',
            sort: false,
            formatter: (cell: any, row: IRoutingRule) => {
                const factory =
                    (row.decision.length === 0) ? 'None'
                        : (row.decision.length === 1) ? row.decision[0].productionSite
                            : 'Multiple'
                const numberFactories = (row.decision.length > 2) ? ` + ${row.decision.length - 2}` : ''
                const factoryList = (row.decision.length > 1) ? `${row.decision[0].productionSite}, ${row.decision[1].productionSite}${numberFactories}` : undefined
                return (
                    <div className="d-flex">
                        <div>
                            <div>{factory}</div>
                            {factoryList && <div className='lighter'>{factoryList}</div>}
                        </div>
                        <div>
                            {factoryList &&
                                <button
                                    className="table-action-icons-container-button ml-3 mt-2"
                                    onClick={() => handleShowRuleDetails(row)}>
                                    <ExpandIcon />
                                </button>}
                        </div>
                    </div>
                );
            },
            classes: 'width-set-10',
        },
        {
            dataField: 'conditions',
            text: 'Conditions',
            sort: false,
            formatter: (cell: any, row: IRoutingRule) => {
                const nrConditions = row.conditions.conditions?.length ?? 0
                return (
                    <div className="d-flex">
                        <div className="mr-3">{nrConditions} Conditions</div>
                        {(nrConditions > 0) && <button className="table-action-icons-container-button" onClick={() => handleShowRuleDetails(row)}><ExpandIcon /></button>}
                    </div>
                );
            },
            classes: 'width-set-15',
        },
        {
            dataField: 'dateTime',
            text: 'Date & Time (local)',
            sort: false,
            formatter: (cell: any, row: IRoutingRule) => {
                const dateRange = getDateRange(row)
                const hasFromDate = dateRange?.activeFrom
                const hasUntilDate = dateRange?.activeUntil
                if (dateRange && (hasFromDate || hasUntilDate)) {
                    const fromMoment = moment(dateRange.activeFrom);
                    const toMoment = moment(dateRange.activeUntil);
                    return (
                        <div>
                            <div className="d-flex justify-content-start">
                                <div className="d-flex flex-column mr-2">
                                    {hasFromDate && <div className="lighter">from:</div>}
                                    {hasUntilDate && <div className="lighter">to:</div>}
                                </div>
                                <div className="d-flex flex-column">
                                    {hasFromDate && <div>{fromMoment.format('DD/MM/YY')}</div>}
                                    {hasUntilDate && <div>{toMoment.format('DD/MM/YY')}</div>}
                                </div>
                                <div className="d-flex flex-column mx-2">
                                    {hasFromDate && <div className="lighter">at</div>}
                                    {hasUntilDate && <div className="lighter">at</div>}
                                </div>
                                <div className="d-flex flex-column">
                                    {hasFromDate && <div>{fromMoment.format('HH:mm')}</div>}
                                    {hasUntilDate && <div>{toMoment.format('HH:mm')}</div>}
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    return (<div>Default</div>)
                };
            },
            classes: 'width-set-20',
        },
        {
            dataField: 'actions',
            text: 'Actions',
            sort: false,
            formatter: (cell: any, row: IRoutingRule) => (
                <>
                    {tableMode === TableMode.Normal && <div className='table-action-icons-container-leftaligned'>
                        <button
                            onClick={() => { handleShowRuleEditModal(row) }}
                            className='table-action-icons-container-button-small'
                        >
                            <EditIconWithCopy
                                copy='Edit'
                                customStyle='table-action-icons-children-small'
                            />
                        </button>
                        <button
                            onClick={() => { handleCloneClick(row) }}
                            className='table-action-icons-container-button-small'
                        >
                            <CloneIconWithCopy
                                copy='Clone'
                                customStyle='table-action-icons-children-small'
                            />
                        </button>
                        <button
                            onClick={() => { handleResetClick(row) }}
                            className={`table-action-icons-container-button-small${getResetButtonVisibilityHeader(row)}`}
                        >
                            <ResetIconWithCopy
                                copy='Reset'
                                customStyle='table-action-icons-children-small'
                            />
                        </button>
                        <button
                            onClick={() => { handleActiveToggleClick(row) }}
                            className='table-action-icons-container-button-small'
                        >
                            <ActiveIconWithCopy
                                active={isActive(row)}
                                copy='Active'
                                customStyle='table-action-icons-children-small'
                            />
                        </button>
                    </div>
                    }
                </>
            ),
            classes: 'width-set-15'
        },
    ];

    return (
        <>
            {(nrApiCallsInProgress > 0) &&
                <div className='d-flex justify-content-center'>
                    <div className="overlay-layer bg-transparent">
                        <div className="spinner spinner-lg spinner-success" />
                    </div>
                </div>
            }
            <BootstrapTable
                wrapperClasses='table-responsive'
                bordered={false}
                classes='table table-head-custom table-vertical-center overflow-hidden semi-bold'
                rowClasses={setRowBackground}
                bootstrap4
                keyField='id'
                data={decisionRules ?? []}
                columns={columns}
                //In import mode, we receive an array which is already sorted for best display of changes, so don't allow sort override then
                sort={(tableMode === TableMode.Normal) ? { dataField: 'sequenceNumber', order: 'asc' } : undefined}
                onTableChange={() => { }}
            >
            </BootstrapTable>
        </>
    );
}

export default RoutingRulesTable;