export enum Environment {
    Local = 'local',
    Test = 'test',
    Acc = 'acc',
    Prod = 'prod'
}

export function getEnvironment(): Environment {
    const local = 'localhost';
    const test = '.tst';
    const acc = '.acc';
    const hostname = window?.location?.hostname

    let environment = Environment.Prod
    if (hostname.includes(local)) {
        environment = Environment.Local
    } else if (hostname.includes(test)) {
        environment = Environment.Test
    } else if (hostname.includes(acc)) {
        environment = Environment.Acc
    }

    return environment
}