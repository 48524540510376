import { Modal } from "react-bootstrap";
import { useRoutingRuleContext } from "../../RoutingRuleContext";
import TestCasesTable from "./TestCasesTable";
import { TableMode } from "../../models/Common";

type Props = {
}

const TestCaseImportCompareModal = (props: Props) => {
    const routingRulesContext = useRoutingRuleContext();

    const handleCloseClick = () => {
        routingRulesContext.uiEvents.hideTestCaseImportCompareModal()
    }

    return (
        <Modal size="xl" show={true} aria-labelledby="example-modal-sizes-title-lg" onEscapeKeyDown={handleCloseClick}>
            <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-lg">
                    Imported TestCase Comparison
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TestCasesTable tableMode={TableMode.Compare} />
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex justify-content-between width-set-100">
                    <div className="d-flex justify-content-end">
                        <button
                            type="button"
                            onClick={handleCloseClick}
                            className='btn btn-outline-theme border-2 fw-bold mr-5 mb-sm-5 mb-md-0'>
                            Close
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal >
    );
}

export default TestCaseImportCompareModal;