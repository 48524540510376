import React from 'react';

type Props = {
  copy: string;
  customStyle?: string;
  color?: string;
};

const ExpandIconWithCopy = ({ customStyle, copy, color }: Props) => (
  <div className={customStyle}>
    <div className='icon'>
      <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 18.5V16.5H3.41L7.91 12L6.5 10.59L2 15.09V11.5H0V18.5H7ZM11.5 8.41L16 3.91V7.5H18V0.5H11V2.5H14.59L10.09 7L11.5 8.41Z" fill="#7E8299" />
      </svg>
    </div>
    <div>{copy}</div>
  </div>
);

export default ExpandIconWithCopy;
