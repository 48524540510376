import { ChangeType } from "./Common"

export interface TestCase {
    id: string,
    testProduct: TestProduct,
    targetProductionSite: string,
    testContext?: TestContext,
    testResult?: TestCaseResult,
    testCaseChange?: TestCaseChange
}

export type TestProduct = {
    channelName: string,
    receiverName?: string,
    destinationCountry: string,
    destinationPostCode?: string,
    articleCode: string,
    deliveryMethod?: string,
    deliveryType?: string,
    productOptions: Object
}

export type TestContext = {
    simulatedRandomValue?: number,
    simulatedEvaluationDate?: string
}

export type SelectedRule = {
    ruleId: string,
    sequenceNumber: number
}

export type TestResult = {
    result: boolean,
    selectedProductionSite: string,
    selectedRule: SelectedRule,
    testCase: TestCase,
    failureReason: string
}

export type TestCaseResult = {
    result: boolean,
    selectedProductionSite: string,
    selectedRule: SelectedRule,
    failureReason: string
}

export type TestCaseChange = {
    changeType: ChangeType,
    originalTestCase: TestCase
}

export function cloneTestCase(test: TestCase) {
    return {
        id: test.id,
        targetProductionSite: test.targetProductionSite,
        testProduct: {
            ...test.testProduct,
            productOptions: {
                ...test.testProduct.productOptions
            }
        },
        testContext: {
            ...test.testContext
        }
    } as TestCase;
}

export function isEqualTestCase(left: TestCase, right: TestCase | undefined): boolean {
    if (right === undefined) {
        return false
    }

    //Required properties on the TestCase
    if (left.id !== right.id ||
        left.targetProductionSite !== right.targetProductionSite) {
        return false
    }

    //Required properties on the TestProduct
    if (left.testProduct.articleCode.toLowerCase() !== right.testProduct.articleCode.toLowerCase() ||
        left.testProduct.channelName !== right.testProduct.channelName ||
        left.testProduct.destinationCountry !== right.testProduct.destinationCountry) {
        return false
    }

    //Optional properties on the TestProduct
    if (!(isEqualOrBothNothing(left.testProduct.deliveryMethod, right.testProduct.deliveryMethod) &&
        isEqualOrBothNothing(left.testProduct.deliveryType, right.testProduct.deliveryType) &&
        isEqualOrBothNothing(left.testProduct.destinationPostCode, right.testProduct.destinationPostCode) &&
        isEqualOrBothNothing(left.testProduct.receiverName, right.testProduct.receiverName))) {
        return false
    }

    //ProductOptions on the TestProduct
    //Get all Options present on either of the comparands
    const leftProductOptionNames = Object.entries(left.testProduct.productOptions).map((kvp) => kvp[0])
    const rightProductOptionNames = Object.entries(right.testProduct.productOptions).map((kvp) => kvp[0])
    const productOptionNames = leftProductOptionNames.concat(rightProductOptionNames)

    //Map either set of options to a dictionary-like structure where we can read the property value dynamically based on property name
    type DirectlyReadable = { [key: string]: string }
    const leftDirectlyReadableOptions = left.testProduct.productOptions as DirectlyReadable
    const rightDirectlyReadableOptions = right.testProduct.productOptions as DirectlyReadable

    //Find any option that is not the same in both comparands
    if (productOptionNames.some((option) => !isEqualOrBothNothing(leftDirectlyReadableOptions[option], rightDirectlyReadableOptions[option]))) {
        return false
    }

    //Optional properties on the TestContext
    if (!(isEqualOrBothNothing(left.testContext?.simulatedEvaluationDate, right.testContext?.simulatedEvaluationDate) &&
        isEqualOrBothNothing(left.testContext?.simulatedRandomValue, right.testContext?.simulatedRandomValue))) {
        return false
    }

    //We won't include TestResult or TestCaseChange in the comparison, only the actual Test Case
    return true
}

function isEqualOrBothNothing(left: any, right: any): boolean {
    if (left === right) {
        return true
    }

    if ((left === undefined || left === null || left === "") &&
        (right === undefined || right === null || right === "")) {
        return true
    }

    return false
}
